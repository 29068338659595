import React from "react";

const Header = () => {
  return (
    <div className="container text-white p-8 mt-[5rem]">
      {/* Container Utama */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* Bagian Kiri: Teks */}
        <div>
            <img src="/assets/logo/logo-cam.png" alt="logo" className="w-[15rem] mb-[3rem]" />
          <h1 className="text-purple-500 text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
            5 EASY STEPS TO START <br /> YOUR CUSTOM LABEL <br /> ACCESSORIES
            DESIGN
          </h1>
          <p className="text-gray-300 text-lg mb-8">
            Accessories for merchandise are crucial for clothing brands as they
            strengthen brand identity, offer effective and affordable promotion,
            increase customer loyalty, spread brand awareness, serve as versatile
            marketing tools, create community engagement, reinforce the brand on
            social media, and support collaborations and limited editions.
          </p>
          {/* Tombol Scroll */}
          <button className="bg-purple-600 hover:bg-purple-800 text-white font-bold py-3 px-6 rounded-full">
            SCROLL DOWN TO SEE THE PROCESS
          </button>
        </div>

        {/* Bagian Kanan: Sticker/Logo */}
        <div className="relative">
          <div className="w-full">
            <img src="/assets/service/D4966.png" alt="logo" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
