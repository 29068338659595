import React, {useState} from 'react';
import { motion } from 'framer-motion';
import { FaTelegramPlane, FaWhatsapp } from 'react-icons/fa';
import { AiOutlineClose } from 'react-icons/ai'; // Import close icon

const ChatButton = ({ isModalOpen }) => {
  // State for toggling chat visibility
  const [isTelegramChatOpen, setIsTelegramChatOpen] = useState(false);
  const [isWhatsappChatOpen, setIsWhatsappChatOpen] = useState(false);

  // Toggle Telegram chat
  const toggleTelegramChat = () => {
    setIsTelegramChatOpen(!isTelegramChatOpen);
    setIsWhatsappChatOpen(false); // Close WhatsApp if open
  };

  // Toggle WhatsApp chat
  const toggleWhatsappChat = () => {
    setIsWhatsappChatOpen(!isWhatsappChatOpen);
    setIsTelegramChatOpen(false); // Close Telegram if open
  };

  // Close chat
  const closeChat = () => {
    setIsTelegramChatOpen(false);
    setIsWhatsappChatOpen(false);
  };

  // Do not render ChatButton when modal is open
  if (isModalOpen) return null;

  return (
    <div className="fixed bottom-5 right-5 z-30 flex flex-col space-y-4">
      {/* Telegram Button */}
      <motion.button
        onClick={toggleTelegramChat}
        className="bg-blue-500 hover:bg-blue-700 text-white p-4 rounded-full shadow-lg focus:outline-none"
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
      >
        {/* Set size for different screen sizes */}
        <FaTelegramPlane className="w-10 h-10 sm:w-8 sm:h-8 md:w-10 md:h-10" />
      </motion.button>

      {/* Telegram Chat Modal */}
      {isTelegramChatOpen && (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          className="absolute bottom-16 right-0 bg-white p-4 rounded-lg shadow-lg w-64"
        >
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Chat dengan Kami</h3>
            <button onClick={closeChat} className="text-gray-600 hover:text-gray-800">
              <AiOutlineClose size={24} />
            </button>
          </div>
          <p className="text-gray-600 text-sm md:text-base">Klik tombol di bawah untuk membuka Telegram dan mulai chatting.</p>
          <a
            href="https://t.me/your_telegram_bot"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-blue-500 text-white px-4 py-2 rounded-full mt-4 block text-center text-sm md:text-base"
          >
            Mulai Chat di Telegram
          </a>
        </motion.div>
      )}

      {/* WhatsApp Button */}
      <motion.button
        onClick={toggleWhatsappChat}
        className="bg-green-500 hover:bg-green-700 text-white p-4 rounded-full shadow-lg focus:outline-none"
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
      >
        {/* Set size for different screen sizes */}
        <FaWhatsapp className="w-10 h-10 sm:w-8 sm:h-8 md:w-10 md:h-10" />
      </motion.button>

      {/* WhatsApp Chat Modal */}
      {isWhatsappChatOpen && (
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          className="absolute bottom-16 right-0 bg-white p-4 rounded-lg shadow-lg w-64"
        >
          <div className="flex justify-between items-center">
            <h3 className="text-lg font-semibold">Chat dengan Kami</h3>
            <button onClick={closeChat} className="text-gray-600 hover:text-gray-800">
              <AiOutlineClose size={24} />
            </button>
          </div>
          <p className="text-gray-600 text-sm md:text-base">Klik tombol di bawah untuk membuka WhatsApp dan mulai chatting.</p>
          <a
            href="https://wa.me/your_whatsapp_number"
            target="_blank"
            rel="noopener noreferrer"
            className="bg-green-500 text-white px-4 py-2 rounded-full mt-4 block text-center text-sm md:text-base"
          >
            Mulai Chat di WhatsApp
          </a>
        </motion.div>
      )}
    </div>
  );
};

export default ChatButton;
