import React from "react";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import Marquee from "react-marquee-slider";
import "./content.css";

const Content = () => {
  const controls = useAnimation();
  const { ref, inView } = useInView({ threshold: 0.1 });

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  const marqueeVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 1.2,
        delay: 0.3,
      },
    },
  };

  const items = [
    "Creative",
    "Professional",
    "Quality Result",
    "Experienced",
    "Communication",
    "Creative",
    "Professional",
    "Quality Result",
    "Experienced",
    "Communication",
    "Creative",
    "Professional",
    "Quality Result",
    "Experienced",
    "Communication",
  ];

  return (
    <section className="relative mt-10 lg:-mt-[15rem]">
      <div className="image-container relative">
        {/* Image is hidden on small screens (sm) and visible on medium (md) and larger screens */}
        <motion.img
          ref={ref}
          initial="hidden"
          animate={controls}
          variants={marqueeVariants}
          src="/assets/front-x.png"
          alt="front-x"
          className="image w-full max-w-[400px] lg:max-w-none mx-auto hidden md:block"
        />
      </div>

      <div className="content-bottom z-0 xl:mt-[-10rem]">
        <div className="bg-black text-lime-400 relative z-0 font-akira">
          <div className="p-2">
            <Marquee velocity={15} direction="ltr">
              {items.map((item, index) => (
                <motion.div
                  key={index}
                  className="flex items-center text-2xl px-4 sm:px-10 sm:text-base transform hover:rotate-0 transition-transform duration-500"
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2, duration: 0.8 }}
                >
                  <img
                    src="/assets/logo/champ.png"
                    alt="icon"
                    className="icon mr-10"
                  />
                  <span>{item}</span>
                </motion.div>
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Content;
