import React from "react";
import "./client.css";
import Marquee from "react-marquee-slider";
import { motion } from "framer-motion";

const icons = [
  "/assets/logo/1.png",
  "/assets/logo/2.png",
  "/assets/logo/3.png",
  "/assets/logo/4.png",
  "/assets/logo/1.png",
  "/assets/logo/2.png",
  "/assets/logo/3.png",
  "/assets/logo/4.png",
];

const icons2 = [
  "/assets/logo/5.png",
  "/assets/logo/6.png",
  "/assets/logo/7.png",
  "/assets/logo/5.png",
  "/assets/logo/6.png",
  "/assets/logo/7.png",
  "/assets/logo/5.png",
  "/assets/logo/6.png",
  "/assets/logo/7.png",
];

const fadeIn = (direction = "up", delay = 0) => {
  return {
    hidden: {
      opacity: 0,
      x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
      y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        duration: 1.2,
        delay: delay,
        ease: "easeInOut",
      },
    },
  };
};

const Client = () => {
  const textStrokeStyle = {
    WebkitTextStroke: "2px #aaa",
    WebkitTextFillColor: "transparent",
  };

  return (
    <section className="client">
      <div className="mt-[-10rem]">
        <div className="text-white p-4 sm:p-8 flex flex-col items-center lg:items-start font-akira">
          <motion.div
            className="ml-[1rem] sm:ml-[2rem] lg:ml-[8rem] mb-[2rem] sm:mb-[3rem] lg:mb-[5rem] text-center lg:text-left"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger animation each time this section enters the viewport
            variants={fadeIn("right", 0.5)}
          >
            {/* CLIENT Text */}
            <div className="text-[2rem] sm:text-[3rem] lg:text-[6rem] font-extrabold tracking-wide leading-none">
              <motion.span
                className="text-white"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.8 }}
                viewport={{ once: false, amount: 0.3 }}
              >
                CLIENT
              </motion.span>
            </div>
            
            {/* COLLABORATION Text */}
            <div className="text-[1.5rem] sm:text-[3rem] lg:text-[6rem] font-extrabold tracking-wide leading-none">
              <motion.span
                className="text-black"
                style={textStrokeStyle}
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.8 }}
                viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
              >
                COLLABORATION
              </motion.span>
            </div>
            
            {/* HIGHLIGHTS Text */}
            <div className="text-[2rem] sm:text-[3rem] lg:text-[6rem] font-extrabold tracking-wide leading-none text-lime-500">
              <motion.span
                className="text-lime-500"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.8 }}
                viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
              >
                HIGHLIGHTS
              </motion.span>
            </div>
            
            {/* Divider Line */}
            <div className="border-t-4 border-blue-600 mt-2 sm:mt-4 w-1/2 lg:w-1/3 mx-auto lg:mx-0"></div>
          </motion.div>

          {/* First Marquee with Icons */}
          <div
            className="p-4 sm:p-6 w-full"
            initial="hidden"
            whileInView="visible"
            variants={fadeIn}
            viewport={{ once: true, amount: 0.3 }}
          >
            <Marquee velocity={20} direction="ltr">
              {icons.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center px-2 sm:px-8 text-lg transform hover:rotate-0 transition-transform duration-500"
                >
                  <img
                    src={item}
                    alt={`Client Logo ${index + 1}`}
                    className="w-[3rem] h-[3rem] sm:w-[5rem] sm:h-[5rem] lg:w-[10rem] lg:h-[10rem]"
                  />
                </div>
              ))}
            </Marquee>
          </div>

          {/* Second Marquee with Icons */}
          <div
            className="p-4 sm:p-6 w-full"
            initial="hidden"
            whileInView="visible"
            variants={fadeIn}
            viewport={{ once: true, amount: 0.3 }}
          >
            <Marquee velocity={20} direction="rtl">
              {icons2.map((item, index) => (
                <div
                  key={index}
                  className="flex items-center px-2 sm:px-8 text-lg transform hover:rotate-0 transition-transform duration-500"
                >
                  <img
                    src={item}
                    alt={`Client Logo ${index + 1}`}
                    className="w-[3rem] h-[3rem] sm:w-[5rem] sm:h-[5rem] lg:w-[10rem] lg:h-[10rem]"
                  />
                </div>
              ))}
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Client;
