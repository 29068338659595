import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import emailjs from "emailjs-com"; // Import EmailJS
import Swal from "sweetalert2"; // Import SweetAlert2
import "sweetalert2/dist/sweetalert2.min.css"; // Import CSS untuk SweetAlert2

// Validation Schema for each step
const stepOneSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email("Invalid email").required("Email is required"),
});

const stepTwoSchema = yup.object().shape({
  services: yup.array().min(1, "Please select at least one service"),
});

const stepThreeSchema = yup.object().shape({
  deadline: yup.string().required("Deadline is required"),
  budget: yup.string().required("Budget is required"),
});

const stepFourSchema = yup.object().shape({
  projectDetails: yup.string().required("Project Details is required"),
});

const MultiStepForm = ({ isModalOpen, closeModal }) => {
  const [step, setStep] = useState(1); // Keep track of form steps

  const {
    register,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(
      step === 1
        ? stepOneSchema
        : step === 2
        ? stepTwoSchema
        : step === 3
        ? stepThreeSchema
        : stepFourSchema
    ),
  });

  const onNextStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      setStep(step + 1);
    }
  };

  const onPreviousStep = () => {
    setStep(step - 1);
  };

  const onSubmit = (data) => {
    // Use EmailJS to send the form data
    emailjs
      .send(
        "service_twip8qe", // Replace with your service ID
        "template_bf3gd6z", // Replace with your template ID
        {
          name: data.name,
          email: data.email,
          phone: data.phone,
          services: data.services.join(", "), // Join the selected services into a string
          deadline: data.deadline,
          budget: data.budget,
          projectDetails: data.projectDetails,
        },
        "o_yQ9SjSOshgiCjA_" // Replace with your public key from EmailJS
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Your form has been submitted successfully.",
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then(() => {
          closeModal(); // Close modal after successful submission
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong. Please try again!",
          confirmButtonColor: "#d33",
          confirmButtonText: "Try Again",
        });
      });
  };

  if (!isModalOpen) return null; // Only render modal when it's open

  return (
    <div className="fixed inset-0 bg-black bg-opacity-70 flex justify-center items-center z-50">
      <motion.div
        className="bg-[#1E1E1E] p-5 md:p-10 rounded-xl w-full max-w-3xl relative border-t-4 border-t-lime-400 max-h-screen overflow-y-auto"
        initial={{ opacity: 0, y: "-100%" }}
        animate={{ opacity: 1, y: "0%" }}
        exit={{ opacity: 0, y: "-100%" }}
        transition={{ duration: 0.6, ease: "easeInOut" }}
      >
        <button
          onClick={closeModal}
          className="absolute top-4 right-4 bg-lime-400 text-black p-2 rounded-full w-[3rem]"
        >
          ✕
        </button>

        {/* Multi-Step Form content */}
        {step === 1 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <div className="text-lime-500 font-bold mb-10 text-2xl md:text-4xl">
              001 <span className="text-white font-extrabold">ABOUT YOU</span>
            </div>
            <div className="space-y-6 md:space-y-10">
              <div className="">
                <label className="text-slate-300">Your Name *</label>
                <input
                  type="text"
                  {...register("name")}
                  className="bg-transparent border-b border-lime-500 p-2 w-full text-white"
                />
                {errors.name && (
                  <p className="text-red-500">{errors.name.message}</p>
                )}
              </div>

              <div className="">
                <label className="text-slate-300">Phone *</label>
                <input
                  type="text"
                  {...register("phone")}
                  className="bg-transparent border-b border-lime-500 p-2 w-full text-white"
                />
                {errors.name && (
                  <p className="text-red-500">{errors.name.message}</p>
                )}
              </div>

              <div>
                <label className="text-slate-300">Email *</label>
                <input
                  type="email"
                  {...register("email")}
                  className="bg-transparent border-b border-lime-500 p-2 w-full text-white"
                />
                {errors.email && (
                  <p className="text-red-500">{errors.email.message}</p>
                )}
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  className="bg-lime-500 text-black px-6 py-2 rounded-full"
                  onClick={onNextStep}
                >
                  NEXT
                </button>
              </div>
            </div>
          </motion.div>
        )}

        {step === 2 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <div className="text-lime-500 font-bold mb-10 text-2xl md:text-4xl">
              002{" "}
              <span className="text-white font-extrabold">
                WHAT CAN WE DO TOGETHER?
              </span>
            </div>
            <div className="space-y-6 md:space-y-8">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <label className=" items-center space-x-2 ">
                  <input
                    type="checkbox"
                    value="BRAND LOGO IDENTITY"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">
                    BRAND LOGO IDENTITY
                  </span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
                <label className="items-center space-x-2">
                  <input
                    type="checkbox"
                    value="ARTWORK MERCH DESIGN"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">
                    ARTWORK MERCH DESIGN
                  </span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
                <label className=" items-center space-x-2">
                  <input
                    type="checkbox"
                    value="ILLUSTRATION DRAWING DESIGN"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">
                    ILLUSTRATION DRAWING DESIGN
                  </span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
                <label className=" items-center space-x-2">
                  <input
                    type="checkbox"
                    value="TECH PACK ILLUSTRATION DESIGN"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">
                    TECH PACK ILLUSTRATION DESIGN
                  </span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
                <label className=" items-center space-x-2">
                  <input
                    type="checkbox"
                    value="FOOTWEAR SPECSHEET DESIGN"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">
                    FOOTWEAR SPECSHEET DESIGN
                  </span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
                <label className=" items-center space-x-2">
                  <input
                    type="checkbox"
                    value="ADVERTISING AND PROMOTION"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">
                    ADVERTISING AND PROMOTION
                  </span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
                <label className=" items-center space-x-2">
                  <input
                    type="checkbox"
                    value="AI SPECIALIST DESIGN"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">
                    AI SPECIALIST DESIGN
                  </span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
                <label className=" items-center space-x-2">
                  <input
                    type="checkbox"
                    value="ACCESSORIES & PACKAGING"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">
                    ACCESSORIES & PACKAGING
                  </span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
                <label className=" items-center space-x-2">
                  <input
                    type="checkbox"
                    value="Other"
                    {...register("services")}
                    className="form-checkbox text-lime-500"
                  />
                  <span className="text-slate-300 text-xl">Other</span>
                  <hr className="border-lime-500 w-full mt-[1rem]" />
                </label>
              </div>
              {errors.services && (
                <p className="text-red-500">{errors.services.message}</p>
              )}

              <div className="flex justify-between">
                <button
                  type="button"
                  className="text-white px-4 py-2"
                  onClick={onPreviousStep}
                >
                  PREVIOUS
                </button>
                <button
                  type="button"
                  className="bg-lime-500 text-black px-6 py-2 rounded-full"
                  onClick={onNextStep}
                >
                  NEXT
                </button>
              </div>
            </div>
          </motion.div>
        )}

        {step === 3 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <h2 className="text-lime-500 font-extrabold text-2xl md:text-4xl mb-10">
              003 <span className="text-white">ABOUT YOUR PROJECT</span>
            </h2>
            <div className="space-y-6 md:space-y-8">
              <div>
                <label className="text-slate-300 text-xl">Deadline *</label>
                <input
                  type="date"
                  {...register("deadline")}
                  className="bg-transparent border-b border-lime-500 p-2 w-full text-white"
                />
                {errors.deadline && (
                  <p className="text-red-500">{errors.deadline.message}</p>
                )}
              </div>

              <div>
                <label className="text-slate-300 text-xl">Budget *</label>
                <select
                  {...register("budget")}
                  className="bg-[#1E1E1E] border-b border-lime-500 p-2 w-full text-white"
                >
                  <option value="<$50">{"<$50"}</option>
                  <option value="$50-$100">$50-$100</option>
                  <option value="$100-$500">$100-$500</option>
                  <option value="$500-$1000">$500-$1000</option>
                  <option value="$1000+">$1000+</option>
                </select>
                {errors.budget && (
                  <p className="text-red-500">{errors.budget.message}</p>
                )}
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  className="text-white px-4 py-2"
                  onClick={onPreviousStep}
                >
                  PREVIOUS
                </button>
                <button
                  type="button"
                  className="bg-lime-500 text-black px-6 py-2 rounded-full"
                  onClick={onNextStep}
                >
                  NEXT
                </button>
              </div>
            </div>
          </motion.div>
        )}

        {/* Final Step */}
        {step === 4 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
            transition={{ duration: 0.5, ease: "easeInOut" }}
          >
            <div className="text-lime-500 font-bold mb-10 text-2xl md:text-4xl">
              004{" "}
              <span className="text-white font-extrabold">PROJECT DETAILS</span>
            </div>
            <div className="space-y-6 md:space-y-10">
              <div className="">
                <label className="text-slate-300 text-xl">
                  Tell us more about your project *
                </label>
                <textarea
                  type="text"
                  {...register("projectDetails")}
                  className="bg-transparent border-b border-lime-500 p-2 w-full text-white"
                />
                {errors.projectDetails && (
                  <p className="text-red-500">
                    {errors.projectDetails.message}
                  </p>
                )}
              </div>

              {/* File Upload Input (Optional) */}
              <div className="">
                <label className="text-slate-300 text-xl">
                  Upload Additional File (optional)
                </label>
                <input
                  type="file"
                  {...register("additionalFile")} // Register the file input, but it's not required
                  className="bg-transparent border-b border-lime-500 p-2 w-full text-white"
                />
              </div>

              <div className="flex justify-between">
                <button
                  type="button"
                  className="text-white"
                  onClick={onPreviousStep}
                >
                  PREVIOUS
                </button>
                <button
                  type="submit"
                  className="bg-lime-500 text-black px-6 py-2 rounded-full"
                  onClick={handleSubmit(onSubmit)}
                >
                  SUBMIT
                </button>
              </div>
            </div>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default MultiStepForm;
