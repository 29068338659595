import React from "react";

const FooterSection = () => {
  return (
    <footer className="bg-black text-white py-10 px-5 mt-[5rem]">
      <div className="max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-10">
        {/* Left Side: Company Info */}
        <div>
          <img
            src="/assets/logo/logo-cam.png"
            alt="Logo"
            className="w-[15rem]"
          />
          <p className="text-sm mt-3">
            Agency with outstanding service design <br/> and professional expertise.
            Based <br/>in Indonesia.
          </p>
          <p className="text-sm text-white mt-3">
            Mojokerto, East Java - Indonesia
          </p>
          <p className="text-sm text-white">hello@camratura.com</p>
          <p className="text-sm text-white">+62 852 3255 6181</p>
        </div>

        {/* Right Side: Social Media Icons */}
        <div className="text-center lg:text-center">
          <h2 className="text-white text-lg font-bold mb-4">FOLLOW US</h2>
          <div className="flex justify-center lg:justify-center space-x-8">
            {/* Instagram */}
            <div className="flex flex-col items-center">
              <a href="instagram.com">
                <div className="w-20 h-20 rounded-full flex justify-center items-center">
                  <img src="/assets/logo/instagram.png" alt="Instagram" />
                </div>
                <p className="mt-2 text-sm">Instagram</p>
              </a>
            </div>
            {/* Tiktok */}
            <div className="flex flex-col items-center">
              <a href="tiktok.com">
                <div className="w-20 h-20 rounded-full flex justify-center items-center">
                  <img src="/assets/logo/tiktok.png" alt="Tiktok" />
                </div>
              </a>

              <p className="mt-2 text-sm">Tiktok</p>
            </div>
            {/* Behance */}
            <div className="flex flex-col items-center">
              <a href="behance.com">
                <div className="w-20 h-20 rounded-full flex justify-center items-center">
                  <img src="/assets/logo/behance.png" alt="Behance" />
                </div>
                <p className="mt-2 text-sm">Behance</p>
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom large text */}
      <div className="mt-20">
        <img
          src="/assets/footer.png"
          alt="footer"
          className="w-full object-cover"
        />
      </div>
    </footer>
  );
};

export default FooterSection;
