import React from "react";
import "./service.css";
import { Link } from 'react-router-dom';

const ServiceCard = ({ className, name, description, image, onLearnMore }) => {
  return (
    <div className={`${className}`}>
      <div className="relative w-full sm:w-72 md:w-80 h-[30rem] sm:h-[35rem] md:h-[40rem] max-w-sm bg-gray-900 text-white rounded-[2rem] shadow-lg overflow-hidden outline  outline-purple-400">
        {/* Background Image and Overlay */}
        <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-tr from-green-600 to-blue-600 opacity-50"></div>
          <div
            className="absolute top-0 left-0 w-full h-full bg-cover bg-center"
            style={{ backgroundImage: `url(${image})` }}
          ></div>
        </div>

        {/* Main Content */}
        <div className="absolute bottom-0 w-full bg-gradient-to-tl from-[#110616] to-[#151518] p-4 rounded-tl-lg  service-card">
          <h2 className="text-lg sm:text-xl md:text-2xl font-extrabold mt-[3rem] md:mt-[5rem] font-akira">
            {name}
          </h2>
          <p className="mt-4 text-xs sm:text-sm md:text-base">{description}</p>

          <div className="mt-2">
            <Link
              to={onLearnMore} // Gunakan Link untuk navigasi ke halaman detail
              className="inline-block px-2 sm:px-3 py-1 bg-transparent text-xs sm:text-base text-[#BBF73D] rounded-lg hover:bg-green-600 hover:text-white"
            >
              READ MORE
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
