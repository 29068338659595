import React from "react";

import { motion } from "framer-motion";

// Helper for the fade-in animation (copy from Service)
const fadeIn = (direction = "up", delay = 0) => {
  return {
    hidden: {
      opacity: 0,
      x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
      y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        duration: 1.2,
        delay: delay,
        ease: "easeInOut",
      },
    },
  };
};
const WhyUs = () => {
  return (
    <section>
      {/* Main Section */}
      <motion.div
        className="text-white p-5 md:p-10 grid grid-cols-1 md:grid-cols-2 gap-10"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}
        variants={fadeIn("up", 0.2)}
      >
        {/* Left Section */}
        <div className="space-y-5 font-akira">
          <motion.h2
            className="text-4xl sm:text-6xl md:text-8xl font-extrabold leading-tight"
            variants={fadeIn("left", 0.3)}
          >
            WHY <br />
            <span className="text-lime-400">CHOOSE</span> <br />
            <span className="text-lime-400">US</span>
          </motion.h2>
          <motion.hr
            className="border-purple-500 w-full"
            variants={fadeIn("right", 0.4)}
          />
        </div>

        {/* Right Section */}
        <motion.div
          className="mt-8 md:mt-[5rem] xl:ml-[10rem]"
          variants={fadeIn("right", 0.5)}
        >
          <div className="text-3xl sm:text-4xl md:text-5xl font-bold font-akira">
            <span>
              SEVERAL <br />
            </span>
            <span className="text-lime-400">REASONS TO</span> <br />
            <span className="text-lime-400">CHOOSE US</span>
          </div>
        </motion.div>
      </motion.div>

      {/* Line Image */}
      <motion.div
        className="relative w-full flex justify-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}
        variants={fadeIn("up", 0.6)}
      >
        <img
          src="/assets/line.png"
          alt="line"
          className="w-full max-w-4xl mt-[-1rem]"
        />
      </motion.div>

      {/* Reason List */}
      <div className="text-white p-5 md:p-10 space-y-10">
        <div className="space-y-10">
          {/* Reason 1 */}
          <motion.div
            className="flex flex-col sm:flex-row justify-between items-start space-y-4 sm:space-y-0"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.2)}
          >
            <div className="text-2xl sm:text-3xl md:text-4xl font-bold">
              FAST, RESPONSIVE, PROFESSIONAL
            </div>
            <p className="text-base sm:text-lg md:text-xl max-w-md">
              We prioritize speed in every project, are responsive to your
              needs, and maintain professional standards in every design step.
            </p>
          </motion.div>

          {/* Line between reasons */}
          <motion.div
            className="relative w-full flex justify-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.3)}
          >
            <img
              src="/assets/line.png"
              alt="line"
              className="w-full max-w-4xl mt-[-1rem]"
            />
          </motion.div>

          {/* Reason 2 */}
          <motion.div
            className="flex flex-col sm:flex-row justify-between items-start space-y-4 sm:space-y-0"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.4)}
          >
            <div className="text-2xl sm:text-3xl md:text-4xl font-bold">
              USE OF LATEST SOFTWARE
            </div>
            <p className="text-base sm:text-lg md:text-xl max-w-md">
              We use the latest legal software to ensure quality and security in
              every design we create.
            </p>
          </motion.div>

          {/* Repeat for other reasons with appropriate delays */}
          {/* Reason 3 */}
          <motion.div
            className="relative w-full flex justify-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.5)}
          >
            <img
              src="/assets/line.png"
              alt="line"
              className="w-full max-w-4xl mt-[-1rem]"
            />
          </motion.div>

          <motion.div
            className="flex flex-col sm:flex-row justify-between items-start space-y-4 sm:space-y-0"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.5)}
          >
            <h4 className="text-2xl sm:text-3xl md:text-4xl font-bold">
              QUALITY CUSTOMER SERVICE
            </h4>
            <p className="text-base sm:text-lg md:text-xl max-w-md">
              We provide friendly and responsive customer service, communicating
              clearly and effectively throughout the project.
            </p>
          </motion.div>
          {/* Repeat for other reasons with appropriate delays */}
          {/* Reason 3 */}
          <motion.div
            className="relative w-full flex justify-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.5)}
          >
            <img
              src="/assets/line.png"
              alt="line"
              className="w-full max-w-4xl mt-[-1rem]"
            />
          </motion.div>

          <motion.div
            className="flex flex-col sm:flex-row justify-between items-start space-y-4 sm:space-y-0"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.5)}
          >
            <h4 className="text-2xl sm:text-3xl md:text-4xl font-bold">
              EXCELLENT AFTER-SALES SERVICE
            </h4>
            <p className="text-base sm:text-lg md:text-xl max-w-md">
              We are committed to providing outstanding after-sales service,
              ensuring customer satisfaction after each project is completed.
            </p>
          </motion.div>
          {/* Repeat for other reasons with appropriate delays */}
          {/* Reason 3 */}
          <motion.div
            className="relative w-full flex justify-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.5)}
          >
            <img
              src="/assets/line.png"
              alt="line"
              className="w-full max-w-4xl mt-[-1rem]"
            />
          </motion.div>

          <motion.div
            className="flex flex-col sm:flex-row justify-between items-start space-y-4 sm:space-y-0"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.5)}
          >
            <h4 className="text-2xl sm:text-3xl md:text-4xl font-bold">
              EXPERT DESIGNERS 100% GUARANTEE
            </h4>
            <p className="text-base sm:text-lg md:text-xl max-w-md">
              Our team consists of experienced and friendly designers who
              deliver the best quality work. We also offer a 100% satisfaction
              guarantee to ensure your trust and satisfaction.
            </p>
          </motion.div>
          {/* Repeat for other reasons with appropriate delays */}
          {/* Reason 3 */}
          <motion.div
            className="relative w-full flex justify-center"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.5)}
          >
            <img
              src="/assets/line.png"
              alt="line"
              className="w-full max-w-4xl mt-[-1rem]"
            />
          </motion.div>

          {/* Add more reasons following the same pattern */}
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
