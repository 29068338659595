import React from "react";
import { motion } from "framer-motion";

const WhoWeAre = () => {
  const textStrokeStyle = {
    WebkitTextStroke: "2px #aaa",
    WebkitTextFillColor: "transparent",
  };

  const strokeStyle = {
    WebkitTextStroke: "2px indigo",
    WebkitTextFillColor: "transparent",
  };

  const fadeIn = (direction = "up", delay = 0) => {
    return {
      hidden: {
        opacity: 0,
        x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
        y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
      },
      visible: {
        opacity: 1,
        x: 0,
        y: 0,
        transition: {
          type: "spring",
          duration: 1.2,
          delay: delay,
          ease: "easeInOut",
        },
      },
    };
  };

  return (
    <section className="flex flex-col lg:flex-row text-white p-5 items-center justify-center lg:justify-between mt-[5rem] gap-4">
      {/* Image Container */}
      <motion.div
        className="relative w-full lg:w-[40%] mb-10 lg:mb-0 flex-shrink-0"
        variants={fadeIn("up", 0.3)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
      >
        <img
          src="/assets/front-2.png"
          alt="White Masked Man"
          className="rounded-lg shadow-xl w-full h-auto"
        />
        <div className="absolute top-0 left-0 w-full h-full rounded-lg"></div>
      </motion.div>

      {/* Text Content */}
      <motion.div
        className="relative w-full lg:w-[60%] flex-shrink-0 text-center lg:text-left"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }} // Trigger animation each time this section enters the viewport
        variants={fadeIn("right", 0.5)}
      >
        {/* WHO WE ARE Text */}
        <div className="text-center  font-akira lg:text-left">
          <div className="text-[2.5rem] sm:text-[4rem] lg:text-[8rem] xl:text-[10rem] font-bold leading-tight">
            {/* Animate WHO */}
            <motion.span
              className="text-white font-extrabold mr-4 lg:mr-[8rem]"
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, duration: 0.8 }}
              viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
            >
              WHO
            </motion.span>

            {/* Animate WE and ARE */}
            <div className="flex justify-center lg:justify-start items-center mt-[-1rem] relative z-10">
              {/* WE with stroke */}
              <motion.span
                className="text-[2.5rem] sm:text-[4rem] lg:text-[8rem] font-bold mr-4"
                style={textStrokeStyle}
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.4, duration: 0.8 }}
                viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
              >
                WE
              </motion.span>

              {/* ARE with lime color */}
              <motion.span
                className="text-lime-400 text-[2.5rem] sm:text-[4rem] lg:text-[8rem] font-bold"
                initial={{ y: 100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.8 }}
                viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
              >
                ARE
              </motion.span>
            </div>
          </div>

          {/* Question Mark Behind Text */}
          <motion.span
            className="absolute z-0 text-[4rem] sm:text-[6rem] lg:text-[16rem] font-bold top-0 left-[50%] lg:left-[70%] translate-x-[-50%]"
            style={strokeStyle}
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            ?
          </motion.span>
        </div>

        <motion.p
          className="mt-6 text-base sm:text-lg lg:text-xl text-gray-300"
          initial={{ y: 100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6, duration: 0.8 }}
          viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
        >
          We're Camratura! We assist brand owners in bringing their visions to
          life, providing exceptional design services and comprehensive support
          for all production needs. Our expertise includes clothing and fashion
          design, innovative footwear design, and production support, ensuring a
          smooth manufacturing process.
        </motion.p>
      </motion.div>
    </section>
  );
};

export default WhoWeAre;
