import React from "react";
import Marquee from "react-marquee-slider";
import { motion } from "framer-motion";

// Helper for the fade-in animation
const fadeIn = (direction = "up", delay = 0) => {
  return {
    hidden: {
      opacity: 0,
      x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
      y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        duration: 1.2,
        delay: delay,
        ease: "easeInOut",
      },
    },
  };
};

const CollaborationSection = () => {
  const items = [
    "CAMRATURA",
    "CAMRATURA",
    "CAMRATURA",
    "CAMRATURA",
    "CAMRATURA",
  ];

  return (
    <div className="mt-8 lg:mt-0">
      {/* Crown icon */}
      <motion.div
        className="relative w-[10rem] sm:w-[15rem] lg:w-[35rem] mx-auto lg:ml-[10rem]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}
        variants={fadeIn("up", 0.2)}
      >
        <img src="/assets/vrown.png" alt="Crown" />
      </motion.div>

      {/* Main text and button */}
      <motion.div
        className="relative text-white text-center mt-[-2rem] lg:-mt-[10rem]"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}
        variants={fadeIn("up", 0.3)}
      >
        <h2 className="text-gray-400 font-bold text-2xl sm:text-4xl mb-2">
          LET'S MAKE SOME NOISE
        </h2>

        {/* "COLLABORATIONS" Text */}
        <div className="text-center font-akira">
          <span className="text-lime-400 text-5xl sm:text-[8rem] lg:text-[10rem] font-bold">
            COLLABO
          </span>
          <br />
          <motion.span
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("right", 0.4)}
            className="text-[#4232C6] text-4xl sm:text-6xl lg:text-8xl font-bold lg:ml-[20rem]"
          >
            RATIONS
          </motion.span>
        </div>

        {/* "Together" Text with CTA */}
        <div className="mt-5 flex justify-center items-center flex-wrap font-akira">
          <motion.span
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("left", 0.5)}
            className="text-white text-3xl sm:text-5xl lg:text-6xl font-bold"
          >
            TOGETHER
          </motion.span>
          <motion.button
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("right", 0.6)}
            className="ml-3 px-4 sm:px-5 py-2 bg-lime-400 text-black rounded-full font-semibold mt-2"
          >
            GET STARTED
          </motion.button>
        </div>
      </motion.div>

      {/* Repeating footer logos */}
      <motion.div
        className="p-4 bg-black text-lime-400 mt-10 font-akira"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: false, amount: 0.3 }}
        variants={fadeIn("up", 0.8)}
      >
        <Marquee velocity={20} direction="ltr">
          {items.map((item, index) => (
            <div
              key={index}
              className="flex items-center px-10 sm:px-20 text-sm sm:text-lg transform hover:rotate-0 transition-transform duration-500"
            >
              <img
                src="/assets/logo/champ.png"
                alt="icon"
                className="w-8 h-8 sm:w-10 sm:h-10 mr-4 sm:mr-10"
              />
              <span>{item}</span>
            </div>
          ))}
        </Marquee>
      </motion.div>
    </div>
  );
};

export default CollaborationSection;
