import React from "react";
import Marquee from "react-marquee-slider";
import "./AutoScrollingSection.css";

const AutoScrollingSection = () => {
  const items = [
    'FOOTWEAR DESIGN',
    'PROMOTION ADS',
    'ARTIFICIAL INTELLIGENCE  DESIGN',
    'ACCESSORIES',
    'BRAND IDENTITY',
    'ARTWORK MERCH',
    'ILLUSTRATION DRAWING',
    'PACKAGING',
    'TECHPACK ILLUSTRATION'

  ];

  return (
    <section>
      <div className="bg-black text-lime-400">
        {/* First marquee section */}
        <div className="p-6">
          <Marquee velocity={20} direction="ltr">
            {items.map((item, index) => (
              <div
                key={index}
                className="flex items-center px-5 text-2xl transform hover:rotate-0 transition-transform duration-500 font-akira"
              >
                <img
                  src="/assets/logo/champ.png"
                  alt="icon"
                  className="icon mr-5"
                />
                <span>{item}</span>
              </div>
            ))}
          </Marquee>
        </div>

        {/* Second marquee section (optional reverse direction) */}
        <div className="p-3">
          <Marquee velocity={20} direction="rtl">
            {items.map((item, index) => (
              <div
                key={index}
                className="flex items-center px-5 text-2xl transform hover:rotate-0 transition-transform duration-500 font-akira"
              >
                <img
                  src="/assets/logo/champ.png"
                  alt="icon"
                  className="icon mr-8"
                />
                <span>{item}</span>
              </div>
            ))}
          </Marquee>
        </div>
      </div>
    </section>
  );
};

export default AutoScrollingSection;
