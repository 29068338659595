import React from "react";
import AutoScrollingSection from "../AutoScrollingSection/AutoScrollingSection";
import ServiceCard from "./ServiceCard";
import { motion } from "framer-motion";
import "./service.css";

// Helper for the fade-in animation
const fadeIn = (direction = "up", delay = 0) => {
  return {
    hidden: {
      opacity: 0,
      x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
      y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        duration: 1.2,
        delay: delay,
        ease: "easeInOut",
      },
    },
  };
};

const Service = () => {
  const textStrokeStyle = {
    WebkitTextStroke: "2px #aaa",
    WebkitTextFillColor: "transparent",
  };

  return (
    <section className="" id="service">
      <AutoScrollingSection />
      <div className="text-white p-8">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }} // Trigger animation each time this section enters the viewport
          variants={fadeIn("right", 0.5)}
          className="grid"
        >
          <div className="flex">
            <div className="space-y-2">
              <div className="text-4xl md:text-7xl lg:text-9xl font-extrabold font-akira">
                <div className="grid grid-cols-1 md:grid-cols-2">
                  <div>
                    <motion.span
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.2, duration: 0.8 }}
                      viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
                      className="block"
                    >
                      WHAT
                    </motion.span>
                    <motion.span
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.4, duration: 0.8 }}
                      viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
                      className="block"
                      style={textStrokeStyle}
                    >
                      CAN
                    </motion.span>
                    <motion.span
                      initial={{ y: 100, opacity: 0 }}
                      whileInView={{ y: 0, opacity: 1 }}
                      transition={{ delay: 0.6, duration: 0.8 }}
                      viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
                      className="block"
                    >
                      WE
                    </motion.span>
                  </div>
                  <div className="mt-[2rem] md:mt-[8rem] md:-ml-20 flex flex-col">
                    <hr className="w-full h-2 border border-[#4232C6] ml-[5rem] mt-[-2rem] hidden sm:block" />
                    <motion.span
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ delay: 0.8, duration: 1 }}
                      viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
                      className="xl:text-7xl  font-extrabold text-lime-400 xl:mt-[2rem]"
                    >
                      DO FOR
                    </motion.span>
                    <motion.span
                      initial={{ opacity: 0, x: -50 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      transition={{ delay: 1, duration: 1 }}
                      viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
                      className="xl:text-7xl font-extrabold text-lime-400"
                    >
                      YOU?
                    </motion.span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Service Cards Section */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[2rem] xl:gap-[5rem]">
          {/* First Service Card */}
          <motion.div
            variants={fadeIn("up", 0.3)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            <ServiceCard
              name={
                <>
                  BRAND <br />
                  LOGO <br />
                  IDENTITY
                </>
              }
              description={`Represents a company's character and values through visual elements like logos, colors, and typography. It's crucial for building trust, recognition, and differentiating from competitors.`}
              image={`/assets/service/Logo-3.png`}
              className=" xl:mt-[100px]"
            />
          </motion.div>

          {/* Repeat for other cards with delays */}
          <motion.div
            variants={fadeIn("up", 0.4)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            <ServiceCard
              name={
                <>
                  ARTWORK
                  <br />
                  MERCH
                  <br />
                  DESIGN
                </>
              }
              description={`Creating visual art and product designs that enhance brand identity and engage customers.`}
              image={`/assets/service/LOGO-4.png`}
              className=" xl:mt-[0px]"
            />
          </motion.div>

          <motion.div
            variants={fadeIn("up", 0.5)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            <ServiceCard
              name={
                <>
                  ILLUSTRATION
                  <br />
                  DRAWING
                  <br />
                  DESIGN
                </>
              }
              description={`Creating visuals to convey ideas or tell stories, making information engaging and memorable.`}
              image={`/assets/service/D4966.png`}
              className=" xl:-mt-[100px]"
              onLearnMore={'/illustration-drawing'}
            />
          </motion.div>

          <motion.div
            variants={fadeIn("up", 0.6)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            <ServiceCard
              name={
                <>
                  TECH PACK
                  <br />
                  ILLUSTRATION
                  <br />
                  DESIGN
                </>
              }
              description={`Detailed design documents for manufacturing, ensuring accuracy in production.`}
              image={`/assets/service/varsity.png`}
              className=" xl:-mt-[200px]"
            />
          </motion.div>

          <motion.div
            variants={fadeIn("up", 0.3)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            <ServiceCard
              name={
                <>
                  FOOTWEAR <br /> SPECSHEET <br /> DESIGN
                </>
              }
              description={`creating detailed designs and speciﬁcations for shoes, ensuring both style and functionality. It's crucial for producing high-quality footwear that meets brand standards and customer expectations.`}
              image={`/assets/service/custom-shoes.png`}
              className=" xl:mt-[100px]"
            />
          </motion.div>

          <motion.div
            variants={fadeIn("up", 0.4)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            <ServiceCard
              name={
                <>
                  ADVERTISING <br /> AND <br /> PROMOTION
                </>
              }
              description={`creation of visually appealing and persuasive advertisements to promote products, services, or brands. It's crucial for capturing attention, conveying messages effectively, and driving consumer action.`}
              image={`/assets/service/ads.png`}
              className=" xl:mt-[0px]"
            />
          </motion.div>

          <motion.div
            variants={fadeIn("up", 0.5)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            <ServiceCard
              name={
                <>
                  AI <br /> SPECIALIST <br /> DESIGN
                </>
              }
              description={`design uses artiﬁcial intelligence to create. innovative and efﬁcient design solutions. it's important for staying ahead of trends, improving accuracy, and saving time in the design process`}
              image={`/assets/service/ai.png`}
              className=" xl:-mt-[100px]"
            />
          </motion.div>

          <motion.div
            variants={fadeIn("up", 0.6)}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }} // Trigger on each scroll
          >
            <ServiceCard
              name={
                <>
                  ACCESSORIES <br /> & PACKAGING
                </>
              }
              description={`involves creating visual elements and layouts for brand to ensure a cohesive and engaging experience. it's crucial for making your
brand memorable, professional, and well-organized
`}
              image={`/assets/service/tag.png`}
              className=" xl:-mt-[200px]"
            />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Service;
