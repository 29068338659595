import React from "react";
import ReactPlayer from "react-player";
import "./banner.css";

const Banner = () => {
  return (
    <section className="relative w-full h-screen flex items-center justify-center overflow-hidden xl:mt-[5rem]" id="banner">
      <div className="absolute top-0 left-0 w-full h-full z-[10]">
        <ReactPlayer
          url="/assets/camraturanewsubs.mp4"
          // url='https://youtu.be/3gLr9UZCr5Y'
          playing
          controls
          muted={false}
          loop
          width="100%"
          height="100%"
          className="react-player"
        />
      </div>
    </section>
  );
};

export default Banner;
