import React, { useState } from "react";
import Headroom from "react-headroom";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link as ScrollLink } from "react-scroll"; // Import Link dari react-scroll
import MultiStepForm from "../modal/Modal";

const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);

  return (
    <>
      <Headroom className="absolute inset-x-0 top-0 z-[99] mt-[1rem]">
        <div className="flex justify-center">
          <div className="bg-black text-white border border-[#BD239F] xl:rounded-full rounded-3xl w-full max-w-6xl mx-auto">
            <div className="container mx-auto flex flex-col md:flex-row items-center justify-between p-4">
              {/* Gambar yang dapat ditekan untuk melakukan scroll */}
              <ScrollLink to="banner" smooth={true} duration={500}>
                <div className="w-[10rem] xl:w-[10rem] cursor-pointer xl:ml-4 xl:mt-2 mt-8 mb-4">
                  <img src="/assets/logo/logo-cam.png" alt="Logo" />
                </div>
              </ScrollLink>

              {/* Navigation for Desktop */}
              <nav className="hidden md:flex space-x-10 mt-4 md:mt-0">
                <ScrollLink
                  to="service" // ID dari section "service"
                  smooth={true} // Mengaktifkan smooth scrolling
                  duration={500} // Durasi scroll dalam milidetik
                  className="hover:text-gray-400 cursor-pointer mt-2"
                >
                  Service
                </ScrollLink>
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  className="hover:text-gray-400 cursor-pointer mt-2"
                >
                  About Us
                </ScrollLink>
                <ScrollLink
                  to="contact"
                  smooth={true}
                  duration={500}
                  className="hover:text-gray-400 cursor-pointer mt-2"
                >
                  Contact
                </ScrollLink>
                <a
                  href="#"
                  className="bg-purple-700 text-white px-4 py-2 rounded-full hover:bg-purple-800"
                >
                  Shop
                </a>
              </nav>

              {/* GET STARTED Button */}
              <div className="hidden md:flex space-x-4 mt-4 md:mt-0">
                <button
                  className="bg-lime-500 text-black px-4 py-2 hover:bg-lime-600 rounded-full"
                  onClick={openModal} // Open modal when clicked
                >
                  GET STARTED
                </button>
              </div>

              {/* Hamburger Icon for Mobile */}
              <div className="md:hidden flex justify-end w-full items-center mb-6 mt-[-3rem]">
                <button onClick={toggleMobileMenu}>
                  {isMobileMenuOpen ? (
                    <FaTimes className="text-white text-2xl" />
                  ) : (
                    <FaBars className="text-white text-2xl" />
                  )}
                </button>
              </div>
            </div>

            {/* Mobile Menu */}
            {isMobileMenuOpen && (
              <nav className="md:hidden bg-black bg-opacity-50 text-white space-y-4 p-4">
                <ScrollLink
                  to="service"
                  smooth={true}
                  duration={500}
                  className="block hover:text-gray-400 cursor-pointer"
                  onClick={toggleMobileMenu} // Close menu when clicked
                >
                  Service
                </ScrollLink>
                <ScrollLink
                  to="about"
                  smooth={true}
                  duration={500}
                  className="block hover:text-gray-400 cursor-pointer"
                  onClick={toggleMobileMenu}
                >
                  About Us
                </ScrollLink>
                <ScrollLink
                  to="contact"
                  smooth={true}
                  duration={500}
                  className="block hover:text-gray-400 cursor-pointer"
                  onClick={toggleMobileMenu}
                >
                  Contact
                </ScrollLink>
                <a
                  href="#"
                  className="block bg-purple-700 text-white px-4 py-2 rounded-lg hover:bg-purple-800"
                >
                  Shop
                </a>
                <button
                  className="w-full bg-lime-500 text-black px-4 py-2 rounded-lg hover:bg-lime-600"
                  onClick={openModal} // Open modal when clicked
                >
                  GET STARTED
                </button>
              </nav>
            )}
          </div>
        </div>
      </Headroom>

      {/* Pass modal open/close logic to the MultiStepForm component */}
      <MultiStepForm isModalOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export default Header;
