// src/App.js
import React from "react";
import Header from "./components/header/header";
import Banner from "./components/banner/banner";
import Service from "./components/service/service";
import Content from "./components/content/Content";
import WhoWeAre from "./components/WhoWeAre/WhoWeAre";
import Client from "./components/client/client";
import CreativeComponent from "./components/creative-component/CreativeComponent";
import WhyUs from "./components/WhyUs/WhyUs";
import CollaborationSection from "./components/CollaborationSection/CollaborationSection";
import FooterSection from "./components/footer/FooterSection";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import IllustrationDrawingDesign from "./pages/service/IllustrationDrawingDesign";
import ChatButton from "./components/ChatButton/ChatButton";

function App() {
  return (
    <Router>
      <div className="min-h-screen">
        <Header />
        <Routes>
          {/* Rute halaman utama */}
          <Route
            path="/"
            element={
              <>
                <Banner />
                <Service />
                <Content />
                <WhoWeAre />
                <Client />
                <CreativeComponent />
                <WhyUs />
                <CollaborationSection />
                <FooterSection />
              </>
            }
          />
          {/* Rute detail layanan */}
          <Route path="/illustration-drawing" element={<IllustrationDrawingDesign />} />
        </Routes>
        <ChatButton />
      </div>
    </Router>
  );
}

export default App;
