import React from "react";
import Marquee from "react-marquee-slider";
import { motion } from "framer-motion";
import "./app.css";

// Helper for the fade-in animation
const fadeIn = (direction = "up", delay = 0) => {
  return {
    hidden: {
      opacity: 0,
      x: direction === "left" ? -100 : direction === "right" ? 100 : 0,
      y: direction === "up" ? 100 : direction === "down" ? -100 : 0,
    },
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        duration: 1.2,
        delay: delay,
        ease: "easeInOut",
      },
    },
  };
};

const CreativeComponent = () => {
  const items = [
    "WE SOLVE PROBLEMS IN A CREATIVE WAY",
    "WE SOLVE PROBLEMS IN A CREATIVE WAY",
    "WE SOLVE PROBLEMS IN A CREATIVE WAY",
  ];

  return (
    <div className="mt-20 font-akira">
      <div className="relative h-screen overflow-hidden">
        <div className="creative-component overflow-hidden">
          {/* Model Head */}
          <motion.div
            className="absolute xl:h-[100%] inset-0 flex justify-end items-end z-50"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: false, amount: 0.3 }}
            variants={fadeIn("up", 0.2)}
          >
            <img
              src="/assets/front-3.png"
              alt="Head Model"
              className="w-[80%] md:w-[60%] lg:w-[40%] h-auto object-contain mr-4 md:mr-10"
            />
          </motion.div>
        </div>

        {/* Marquee 1 - Blue */}
        <motion.div
          className="absolute top-1/2 left-0 w-full z-20 transform -translate-y-1/2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          variants={fadeIn("up", 0.3)}
        >
          <div className="bg-[#4232C6] text-white relative z-20 rotate-6">
            <div className="p-2">
              <Marquee velocity={20} direction="ltr">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center px-6 lg:px-20 text-sm lg:text-2xl transform hover:rotate-0 transition-transform duration-500"
                  >
                    <span>{item}</span>
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </motion.div>

        {/* Marquee 2 - Black */}
        <motion.div
          className="absolute top-1/2 left-0 w-full z-10 transform -translate-y-1/2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          variants={fadeIn("up", 0.4)}
        >
          <div className="bg-black text-white relative z-10">
            <div className="p-2">
              <Marquee velocity={20} direction="ltr">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center px-6 lg:px-20 text-sm lg:text-2xl transform hover:rotate-0 transition-transform duration-500"
                  >
                    <span>{item}</span>
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </motion.div>

        {/* Marquee 3 - Blue */}
        <motion.div
          className="absolute top-1/2 left-0 w-full z-0 transform -translate-y-1/2"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: false, amount: 0.3 }}
          variants={fadeIn("up", 0.5)}
        >
          <div className="bg-[#4232C6] text-white relative z-0 -rotate-6">
            <div className="p-2">
              <Marquee velocity={20} direction="ltr">
                {items.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center px-6 lg:px-20 text-sm lg:text-2xl transform hover:rotate-0 transition-transform duration-500"
                  >
                    <span>{item}</span>
                  </div>
                ))}
              </Marquee>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CreativeComponent;
